@import '../../styles/customMediaQueries.css';

.contactUsBox {
  position: fixed;
  z-index: 10000;
  right: 20px;
  bottom: 50px;
  border-radius: 50%;
  padding: 15px;
  background-color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: var(--boxShadowFixedButtons);
  transition: all 0.5s ease;

  @media (--viewportSmall) {
    bottom: 20px;
  }
}
