@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@-webkit-keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  -webkit-animation-name: animationTitle;
  animation-name: animationTitle;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* composes: defaultBackgroundImage from global; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}
.icon {
  display: inline-block;

  margin: 0 4px;
  text-align: center;
}

.someLinks {
  display: block;
  justify-content: center;
  margin-top: 24px;
  padding-left: 0px;

  @media (--viewportMedium) {
    display: flex;
    padding-left: 8pxpadding-left;
  }
}
.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.heroMainTitle {
  composes: h2 from global;
  color: var(--matterColorDark);
  composes: animation;
  text-align: center;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}
.heroMainTitleFEDelay {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}
.heroSubTitleFEDelay {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  margin-left: auto;
  margin-right: auto;
  margin: 8px;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.heroButtonFEDelay {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
