.parent {
  display: flex;
  justify-content: center;
  margin: 30px;
}
.box {
  background-color: transparent;
  border-radius: 25px;
  color: #fff;
  display: inline-flex;
  margin-left: 20px;
  width: 100%;
  max-width: 800px;
  height: 300px;
  transform-style: preserve-3d;
  perspective: 2000px;
  transition: 0.4s;
  text-align: center;
  box-shadow: 0px 8px 16px 8px rgb(0 0 0 / 20%);
}
.box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-top: 20px solid #fff;
  border-left: 20px solid #fff;
  box-sizing: border-box;
}
.box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 20px solid #fff;
  border-right: 20px solid #fff;
  box-sizing: border-box;
}
.box .fas {
  font-size: 25px;
  height: 50px;
  width: 50px;
  line-height: 50px !important;
  background-color: #fff;
  color: var(--marketplaceColor);
}
.box .fa2 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.box .text {
  position: absolute;
  top: 30px;
  left: -30px;
  width: calc(100% + 60px);
  height: calc(100% - 60px);
  background: var(--marketplaceColorGradient);
  background-color: var(--marketplaceColor);
  border-radius: 3px;
  transition: 0.4s;

  box-shadow: none;
}
.box .text .fa1 {
  position: absolute;
  top: 0;
  left: 0;
}
.box .text div {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 30px 60px;
  line-height: 1.5;
  box-sizing: border-box;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}
.box .text div h3 {
  font-size: 30px;
  margin-bottom: 5px;
}
.box .text div p {
  font-size: 15px;
}
.box:hover {
  transform: rotateY(-5deg) skewY(3deg);
}
.box:hover .text {
  transform: rotateY(5deg) skewY(-3deg);
  box-shadow: 0px 4px 16px 4px rgb(0 0 0 / 20%);
}
