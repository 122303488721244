:root {
  --primary-color: #000;
  --secondary-color: #0f08af;
  --tertiary-color: #fff;
  --success-color: #00b052;
  --warning-color: #ffa000;
  --error-color: #d12c2c;
  --text-light-color: #6e6e6e;
  --text-dark-color: #44444f;
  --border-color: #e9e9e9;
  --success-color-bg: #b2dfdb;
  --warning-color-bg: #ffecb3;
  --error-color-bg: #ffcdd2;

  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;
  --space-5: 16rem;

  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --caps-letter-spacing: 0.2em;
  --bold-font-weight: 700;

  --border-width: 1px;
  --border-radius: 5px;

  --width-1: 21.5rem;
  --width-2: 8rem;
  --width-3: 48rem;
  --width-4: 64rem;

  --z1: 1;
  --z2: 2;
  --z3: 3;
  --z4: 4;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.rounded {
  border-radius: 6px !important;
}

.shadow {
  box-shadow: 0 0 3px rgb(60 72 88 / 15%) !important;
}

.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.table {
  display: table;
}
.table-cell {
  display: table-cell;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto {
  overflow: auto;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}

.left {
  float: left;
}
.right {
  float: right;
}

.fit {
  width: 100%;
}
.fit-95 {
  width: 95%;
}
.fit-90 {
  width: 90%;
}
.fit-85 {
  width: 85%;
}
.fit-80 {
  width: 80%;
}
.fit-75 {
  width: 75%;
}
.fit-70 {
  width: 70%;
}
.fit-65 {
  width: 65%;
}
.fit-60 {
  width: 60%;
}
.fit-55 {
  width: 55%;
}
.fit-50 {
  width: 50%;
}
.fit-45 {
  width: 45%;
}
.fit-40 {
  width: 40%;
}
.fit-35 {
  width: 35%;
}
.fit-30 {
  width: 30%;
}
.fit-25 {
  width: 25%;
}
.fit-20 {
  width: 20%;
}
.fit-15 {
  width: 15%;
}
.fit-10 {
  width: 10%;
}
.fit-5 {
  width: 5%;
}

.max-width-1 {
  max-width: var(--width-1);
}
.max-width-2 {
  max-width: var(--width-2);
}
.max-width-3 {
  max-width: var(--width-3);
}
.max-width-4 {
  max-width: var(--width-4);
}

.min-height-4 {
  min-height: var(--width-4);
}

.min-width-1 {
  min-width: var(--width-1);
}
.min-width-2 {
  min-width: var(--width-2);
}
.min-width-3 {
  min-width: var(--width-3);
}
.min-width-4 {
  min-width: var(--width-4);
}

.border-box {
  box-sizing: border-box;
}

.column-2 {
  -moz-columns: 2;
  columns: 2;
}

/* -------------------- Colors -------------------------- */
.grey-text {
  color: var(--text-light-color) !important;
}

.blue-text {
  color: var(--secondary-color) !important;
}

.dark-text {
  color: var(--text-dark-color) !important;
}

.success-text {
  color: var(--success-color);
}

.success-bg {
  background-color: var(--success-color) !important;
}

.success {
  color: var(--success-color) !important;
  background-color: var(--success-color-bg) !important;
}

.error-text {
  color: var(--error-color);
}

.error-bg {
  background-color: var(--error-color) !important;
}

.error {
  color: var(--error-color) !important;
  background-color: var(--error-color-bg) !important;
}

.warning-text {
  color: var(--warning-color) !important;
}

.warning-bg {
  background-color: var(--warning-color-bg) !important;
}

.warning {
  color: var(--warning-color) !important;
  background-color: var(--warning-color-bg) !important;
}
