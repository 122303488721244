@import '../../styles/customMediaQueries.css';

.root {
}

/* Shared styles for infoText variants */
.infoText {
  composes: h4 from global;

  display: inline-block;
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: top 7px left;

  margin-top: 11px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 14px;
    margin-bottom: 1px;
  }
}
.contactDetailsSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
    padding-top: 0;
  }
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.emailVerified {
  composes: infoText;
  color: var(--successColor);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%232ECC71" fill-rule="evenodd"/></svg>');
}

.emailUnverified {
  composes: infoText;
  color: var(--matterColorAnti);
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.pendingEmailUnverified {
  composes: infoText;
  color: var(--failColor);
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.52 9.098c-.163.188-.397.3-.646.31h-.032c-.238 0-.466-.094-.635-.263L2.783 6.732c-.353-.35-.354-.92-.003-1.273.35-.353.92-.354 1.272-.004L5.794 7.19l4.59-5.278C9.287.738 7.73 0 6 0 2.686 0 0 2.686 0 6c0 3.313 2.686 6 6 6 3.313 0 6-2.687 6-6 0-.91-.21-1.772-.573-2.545L6.52 9.098z" fill="%23F00" fill-rule="evenodd"/></svg>');
}

.checkInbox {
  color: var(--matterColor);
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.helperLink {
  color: var(--matterColor);
  border-bottom: solid 1px var(--matterColor);
  line-height: 20px;
  margin: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.tooMany {
  color: var(--matterColorAnti);
}

.emailSent {
  color: var(--matterColor);
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 46px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}
