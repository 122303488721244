@import '../../styles/customMediaQueries.css';

.container {
  position: fixed;
  top: 17%;
}
.styleSwitcher {
  transition: all 0.4s;
  background: none repeat scroll 0 0 #ffffff;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;
  border: 1px solid #e9ecef;
  background-color: #f8f9fc;
  padding: 0.5rem 0;
}

.styleSwitcher div {
  padding: 5px 10px;
}

.styleSwitcher div h3 {
  color: #3c4858;
  font-size: 16px;
  margin: 0 3px 12px;
}

@media only screen and (max-width: 375px) {
  .styleSwitcher {
    display: none;
  }
}

.settings {
  background: none repeat scroll 0 0 #ffffff;
  height: 40px;
  position: absolute;
  right: -40px;
  top: 0px;
  width: 40px;
  padding: 3px;
  border-radius: 0 30px 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.slideInLeft {
  -webkit-animation: slideInLeft 0.5s ease-in-out;
  animation: slideInLeft 0.5s ease-in-out;
}

@-webkit-keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
