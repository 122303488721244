.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

textarea {
  height: 90px;
  padding: 4px;
}
