@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 24px;

  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
  border-bottom-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  /* @media (--viewportLarge) {
    padding: 109px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 109px 0 82px calc((100% - 1056px) / 4);
  } */
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  /* @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  } */
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  /* @media (--viewportLarge) {
    margin-left: 0;
  } */
}
