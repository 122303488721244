@import '../../styles/customMediaQueries.css';

/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  General              *
 *     03.  Bootstrap Custom     *
 *     04.  Components           *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Team                 *
 *     11.  Blog / Careers / Job *
 *     12.  Contact              *
 *     13.  Footer               *
 ================================*/
/*********************************/
/*         Variables             */
/*===============================*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap');
/*********************************/
/*         General               */
/*===============================*/

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  line-height: 1.3;
}
.mx2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.p2 {
  padding: 0.5rem !important;
}

::-moz-selection {
  background: rgba(16, 102, 231, 0.9);
  color: #ffffff;
}

::-moz-selection {
  background: rgba(16, 102, 231, 0.9);
  color: #ffffff;
}

::selection {
  background: rgba(16, 102, 231, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
  transition: all 0.5s ease;
}

p {
  line-height: 1.6;
}

/*********************************/
/*         Bootstrap-custom      */
/*===============================*/
.textMuted {
  color: #8492a6 !important;
}

.small,
small {
  font-size: 90%;
}

.card {
  background: transparent;
  border: 0;
  border-radius: 10px !important;
  box-shadow: 0 0 3px rgb(60 72 88 / 15%) !important;
  background-color: rgba(255, 255, 255, 1) !important;
  margin-bottom: 1rem !important;
  overflow: hidden !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

/*********************************/
/*         Components            */
/*===============================*/
.btn-primary {
  background-color: var(--marketplaceColor) !important;
  border: 1px solid var(--marketplaceColor) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(16, 102, 231, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
  background-color: var(--marketplaceColorDark) !important;
  border-color: var(--marketplaceColorDark) !important;
  color: #ffffff !important;
}

.btn-soft-primary {
  background-color: rgba(16, 102, 231, 0.1) !important;
  border: 1px solid rgba(16, 102, 231, 0.1) !important;
  color: var(--marketplaceColor) !important;
  box-shadow: 0 3px 5px 0 rgba(16, 102, 231, 0.3);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: var(--marketplaceColor) !important;
  border-color: var(--marketplaceColor) !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(16, 102, 231, 0.3);
}

.btn-link.primary {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.primary:hover,
.btn-link.primary:focus,
.btn-link.primary:active,
.btn-link.primary.active,
.btn-link.primary.focus,
.btn-link.primary:not(:disabled):not(.disabled):active {
  color: var(--marketplaceColor) !important;
}

.btn-link.primary:after {
  background-color: var(--marketplaceColor) !important;
}

.btn-secondary {
  background-color: var(--marketplaceColorLight) !important;
  border: 1px solid var(--marketplaceColorLight) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-soft-secondary {
  background-color: rgba(90, 109, 144, 0.1) !important;
  border: 1px solid rgba(90, 109, 144, 0.1) !important;
  color: var(--marketplaceColorLight) !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active,
.btn-soft-secondary.focus {
  background-color: var(--marketplaceColorLight) !important;
  border-color: var(--marketplaceColorLight) !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid var(--marketplaceColorLight);
  color: var(--marketplaceColorLight);
  background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--marketplaceColorLight);
  border-color: var(--marketplaceColorLight);
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-link.secondary {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.secondary:hover,
.btn-link.secondary:focus,
.btn-link.secondary:active,
.btn-link.secondary.active,
.btn-link.secondary.focus,
.btn-link.secondary:not(:disabled):not(.disabled):active {
  color: var(--marketplaceColorLight) !important;
}

.btn-link.secondary:after {
  background-color: var(--marketplaceColorLight) !important;
}

.btn-success {
  background-color: #1cb59f !important;
  border: 1px solid #1cb59f !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(28, 181, 159, 0.3);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
  background-color: #158978 !important;
  border-color: #158978 !important;
  color: #ffffff !important;
}

.btn-soft-success {
  background-color: rgba(28, 181, 159, 0.1) !important;
  border: 1px solid rgba(28, 181, 159, 0.1) !important;
  color: #1cb59f !important;
  box-shadow: 0 3px 5px 0 rgba(28, 181, 159, 0.3);
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus {
  background-color: #1cb59f !important;
  border-color: #1cb59f !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #1cb59f;
  color: #1cb59f;
  background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #1cb59f;
  border-color: #1cb59f;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(28, 181, 159, 0.3);
}

.btn-link.success {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.success:hover,
.btn-link.success:focus,
.btn-link.success:active,
.btn-link.success.active,
.btn-link.success.focus,
.btn-link.success:not(:disabled):not(.disabled):active {
  color: #1cb59f !important;
}

.btn-link.success:after {
  background-color: #1cb59f !important;
}

.btn-warning {
  background-color: #fa693e !important;
  border: 1px solid #fa693e !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(250, 105, 62, 0.3);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
  background-color: #f9420c !important;
  border-color: #f9420c !important;
  color: #ffffff !important;
}

.btn-soft-warning {
  background-color: rgba(250, 105, 62, 0.1) !important;
  border: 1px solid rgba(250, 105, 62, 0.1) !important;
  color: #fa693e !important;
  box-shadow: 0 3px 5px 0 rgba(250, 105, 62, 0.3);
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus {
  background-color: #fa693e !important;
  border-color: #fa693e !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #fa693e;
  color: #fa693e;
  background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #fa693e;
  border-color: #fa693e;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(250, 105, 62, 0.3);
}

.btn-link.warning {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.warning:hover,
.btn-link.warning:focus,
.btn-link.warning:active,
.btn-link.warning.active,
.btn-link.warning.focus,
.btn-link.warning:not(:disabled):not(.disabled):active {
  color: #fa693e !important;
}

.btn-link.warning:after {
  background-color: #fa693e !important;
}

.btn-info {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.btn-soft-info {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-link.info {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.info:hover,
.btn-link.info:focus,
.btn-link.info:active,
.btn-link.info.active,
.btn-link.info.focus,
.btn-link.info:not(:disabled):not(.disabled):active {
  color: #17a2b8 !important;
}

.btn-link.info:after {
  background-color: #17a2b8 !important;
}

.btn-danger {
  background-color: #d93030 !important;
  border: 1px solid #d93030 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(217, 48, 48, 0.3);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
  background-color: #b52121 !important;
  border-color: #b52121 !important;
  color: #ffffff !important;
}

.btn-soft-danger {
  background-color: rgba(217, 48, 48, 0.1) !important;
  border: 1px solid rgba(217, 48, 48, 0.1) !important;
  color: #d93030 !important;
  box-shadow: 0 3px 5px 0 rgba(217, 48, 48, 0.3);
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus {
  background-color: #d93030 !important;
  border-color: #d93030 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #d93030;
  color: #d93030;
  background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #d93030;
  border-color: #d93030;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(217, 48, 48, 0.3);
}

.btn-link.danger {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.danger:hover,
.btn-link.danger:focus,
.btn-link.danger:active,
.btn-link.danger.active,
.btn-link.danger.focus,
.btn-link.danger:not(:disabled):not(.disabled):active {
  color: #d93030 !important;
}

.btn-link.danger:after {
  background-color: #d93030 !important;
}

.btn-dark {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-soft-dark {
  background-color: rgba(60, 72, 88, 0.1) !important;
  border: 1px solid rgba(60, 72, 88, 0.1) !important;
  color: #3c4858 !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus {
  background-color: #3c4858 !important;
  border-color: #3c4858 !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-link.dark {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.dark:hover,
.btn-link.dark:focus,
.btn-link.dark:active,
.btn-link.dark.active,
.btn-link.dark.focus,
.btn-link.dark:not(:disabled):not(.disabled):active {
  color: #3c4858 !important;
}

.btn-link.dark:after {
  background-color: #3c4858 !important;
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-soft-muted {
  background-color: rgba(132, 146, 166, 0.1) !important;
  border: 1px solid rgba(132, 146, 166, 0.1) !important;
  color: #8492a6 !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus {
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-link.muted {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.muted:hover,
.btn-link.muted:focus,
.btn-link.muted:active,
.btn-link.muted.active,
.btn-link.muted.focus,
.btn-link.muted:not(:disabled):not(.disabled):active {
  color: #8492a6 !important;
}

.btn-link.muted:after {
  background-color: #8492a6 !important;
}

.btn-light {
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.btn-soft-light {
  background-color: rgba(248, 249, 252, 0.1) !important;
  border: 1px solid rgba(248, 249, 252, 0.1) !important;
  color: #f8f9fc !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus {
  background-color: #f8f9fc !important;
  border-color: #f8f9fc !important;
  color: #ffffff !important;
}

.btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-link.light {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.light:hover,
.btn-link.light:focus,
.btn-link.light:active,
.btn-link.light.active,
.btn-link.light.focus,
.btn-link.light:not(:disabled):not(.disabled):active {
  color: #f8f9fc !important;
}

.btn-link.light:after {
  background-color: #f8f9fc !important;
}

.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.1) !important;
  border: 1px solid rgba(32, 41, 66, 0.1) !important;
  color: #202942 !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-soft-footer:hover,
.btn-soft-footer:focus,
.btn-soft-footer:active,
.btn-soft-footer.active,
.btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-link.footer {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.btn-link.footer:hover,
.btn-link.footer:focus,
.btn-link.footer:active,
.btn-link.footer.active,
.btn-link.footer.focus,
.btn-link.footer:not(:disabled):not(.disabled):active {
  color: #202942 !important;
}

.btn-link.footer:after {
  background-color: #202942 !important;
}

.btn {
  padding: 5.75px 20px;
  outline: none;
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #3c4858 !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active,
.btn.btn-light.focus {
  background-color: #d4daed !important;
  color: #3c4858 !important;
}

.btn.btn-soft-light {
  color: rgba(60, 72, 88, 0.5) !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-soft-light:hover,
.btn.btn-soft-light:focus,
.btn.btn-soft-light:active,
.btn.btn-soft-light.active,
.btn.btn-soft-light.focus {
  color: #3c4858 !important;
}

.btn.btn-outline-light {
  border: 1px solid #dee2e6 !important;
  color: #3c4858 !important;
  background-color: transparent;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active,
.btn.btn-outline-light.focus {
  background-color: #f8f9fc !important;
}

.btn.btn-outline-light-white {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  background-color: transparent;
}

.btn.btn-outline-light-white:hover,
.btn.btn-outline-light-white:focus,
.btn.btn-outline-light-white:active,
.btn.btn-outline-light-white.active,
.btn.btn-outline-light-white.focus {
  background-color: #f8f9fc !important;
  color: #3c4858 !important;
}

.btn.btn-link {
  font-weight: 500;
}

.btn.btn-link:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.5s ease;
}

.btn.btn-link.active:after,
.btn.btn-link:hover:after {
  width: 100%;
  right: auto;
  left: 0;
  height: 1px;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.badge {
  letter-spacing: 0.5px;
  padding: 4px 8px;
  font-weight: 400;
}

.badge.badge-link:hover {
  color: #ffffff !important;
}

.dropdown-primary .dropdown-menu .dropdown-item:hover,
.dropdown-primary .dropdown-menu .dropdown-item.active,
.dropdown-primary .dropdown-menu .dropdown-item:active,
.dropdown-primary .dropdown-menu .dropdown-item.focus,
.dropdown-primary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: var(--marketplaceColor) !important;
}

.dropdown-secondary .dropdown-menu .dropdown-item:hover,
.dropdown-secondary .dropdown-menu .dropdown-item.active,
.dropdown-secondary .dropdown-menu .dropdown-item:active,
.dropdown-secondary .dropdown-menu .dropdown-item.focus,
.dropdown-secondary .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: var(--marketplaceColorLight) !important;
}

.dropdown-success .dropdown-menu .dropdown-item:hover,
.dropdown-success .dropdown-menu .dropdown-item.active,
.dropdown-success .dropdown-menu .dropdown-item:active,
.dropdown-success .dropdown-menu .dropdown-item.focus,
.dropdown-success .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #1cb59f !important;
}

.dropdown-warning .dropdown-menu .dropdown-item:hover,
.dropdown-warning .dropdown-menu .dropdown-item.active,
.dropdown-warning .dropdown-menu .dropdown-item:active,
.dropdown-warning .dropdown-menu .dropdown-item.focus,
.dropdown-warning .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #fa693e !important;
}

.dropdown-info .dropdown-menu .dropdown-item:hover,
.dropdown-info .dropdown-menu .dropdown-item.active,
.dropdown-info .dropdown-menu .dropdown-item:active,
.dropdown-info .dropdown-menu .dropdown-item.focus,
.dropdown-info .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #17a2b8 !important;
}

.dropdown-danger .dropdown-menu .dropdown-item:hover,
.dropdown-danger .dropdown-menu .dropdown-item.active,
.dropdown-danger .dropdown-menu .dropdown-item:active,
.dropdown-danger .dropdown-menu .dropdown-item.focus,
.dropdown-danger .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #d93030 !important;
}

.dropdown-dark .dropdown-menu .dropdown-item:hover,
.dropdown-dark .dropdown-menu .dropdown-item.active,
.dropdown-dark .dropdown-menu .dropdown-item:active,
.dropdown-dark .dropdown-menu .dropdown-item.focus,
.dropdown-dark .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #3c4858 !important;
}

.dropdown-muted .dropdown-menu .dropdown-item:hover,
.dropdown-muted .dropdown-menu .dropdown-item.active,
.dropdown-muted .dropdown-menu .dropdown-item:active,
.dropdown-muted .dropdown-menu .dropdown-item.focus,
.dropdown-muted .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #8492a6 !important;
}

.dropdown-light .dropdown-menu .dropdown-item:hover,
.dropdown-light .dropdown-menu .dropdown-item.active,
.dropdown-light .dropdown-menu .dropdown-item:active,
.dropdown-light .dropdown-menu .dropdown-item.focus,
.dropdown-light .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #f8f9fc !important;
}

.dropdown-footer .dropdown-menu .dropdown-item:hover,
.dropdown-footer .dropdown-menu .dropdown-item.active,
.dropdown-footer .dropdown-menu .dropdown-item:active,
.dropdown-footer .dropdown-menu .dropdown-item.focus,
.dropdown-footer .dropdown-menu .dropdown-item:focus {
  background-color: transparent;
  color: #202942 !important;
}

.btn-group .dropdown-toggle:after {
  content: '';
  position: relative;
  right: -4px;
  top: -2px;
  border: solid #ffffff;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 0;
  vertical-align: 0;
}

.btn-group .dropdown-menu {
  margin-top: 10px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.btn-group .dropdown-menu:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 45px;
  box-sizing: border-box;
  border: 7px solid #495057;
  border-radius: 0.5px;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, 0.15);
}

.dropdown .dropdown-toggle:after {
  display: none;
}

.alert-primary {
  background-color: rgba(16, 102, 231, 0.9);
  color: #ffffff;
  border-color: var(--marketplaceColor);
}

.alert-primary .alert-link {
  color: #062758;
}

.alert-outline-primary {
  background-color: #ffffff;
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.alert-secondary {
  background-color: rgba(90, 109, 144, 0.9);
  color: #ffffff;
  border-color: var(--marketplaceColorLight);
}

.alert-secondary .alert-link {
  color: #1f2632;
}

.alert-outline-secondary {
  background-color: #ffffff;
  color: var(--marketplaceColorLight);
  border-color: var(--marketplaceColorLight);
}

.alert-success {
  background-color: rgba(28, 181, 159, 0.9);
  color: #ffffff;
  border-color: #1cb59f;
}

.alert-success .alert-link {
  color: #08302b;
}

.alert-outline-success {
  background-color: #ffffff;
  color: #1cb59f;
  border-color: #1cb59f;
}

.alert-warning {
  background-color: rgba(250, 105, 62, 0.9);
  color: #ffffff;
  border-color: #fa693e;
}

.alert-warning .alert-link {
  color: #9b2704;
}

.alert-outline-warning {
  background-color: #ffffff;
  color: #fa693e;
  border-color: #fa693e;
}

.alert-info {
  background-color: rgba(23, 162, 184, 0.9);
  color: #ffffff;
  border-color: #17a2b8;
}

.alert-info .alert-link {
  color: #062a30;
}

.alert-outline-info {
  background-color: #ffffff;
  color: #17a2b8;
  border-color: #17a2b8;
}

.alert-danger {
  background-color: rgba(217, 48, 48, 0.9);
  color: #ffffff;
  border-color: #d93030;
}

.alert-danger .alert-link {
  color: #5f1111;
}

.alert-outline-danger {
  background-color: #ffffff;
  color: #d93030;
  border-color: #d93030;
}

.alert-dark {
  background-color: rgba(60, 72, 88, 0.9);
  color: #ffffff;
  border-color: #3c4858;
}

.alert-dark .alert-link {
  color: black;
}

.alert-outline-dark {
  background-color: #ffffff;
  color: #3c4858;
  border-color: #3c4858;
}

.alert-muted {
  background-color: rgba(132, 146, 166, 0.9);
  color: #ffffff;
  border-color: #8492a6;
}

.alert-muted .alert-link {
  color: #3d4654;
}

.alert-outline-muted {
  background-color: #ffffff;
  color: #8492a6;
  border-color: #8492a6;
}

.alert-light {
  background-color: rgba(248, 249, 252, 0.9);
  color: #ffffff;
  border-color: #f8f9fc;
}

.alert-light .alert-link {
  color: #8d9dce;
}

.alert-outline-light {
  background-color: #ffffff;
  color: #f8f9fc;
  border-color: #f8f9fc;
}

.alert-footer {
  background-color: rgba(32, 41, 66, 0.9);
  color: #ffffff;
  border-color: #202942;
}

.alert-footer .alert-link {
  color: black;
}

.alert-outline-footer {
  background-color: #ffffff;
  color: #202942;
  border-color: #202942;
}

.alert {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
}

.alert.alert-light {
  background-color: white;
  color: #3c4858;
  border-color: #e9ecef;
}

.alert.alert-dismissible {
  padding-right: 30px;
}

.alert.alert-dismissible .btn-close {
  top: 10px;
  right: 10px;
  padding: 0px;
}

.alert.alert-pills {
  border-radius: 30px;
  display: inline-block;
}

.alert.alert-pills .content {
  font-weight: 600;
}

.breadcrumb {
  letter-spacing: 0.5px;
  padding: 8px 24px;
  display: block;
}

.breadcrumb.breadcrumb-muted .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb.breadcrumb-muted .breadcrumb-item a:hover {
  color: #ffffff !important;
}

.breadcrumb.breadcrumb-muted .breadcrumb-item:after {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb.breadcrumb-muted .breadcrumb-item.active {
  color: #ffffff !important;
}

.breadcrumb .breadcrumb-item {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0;
  display: inline-block;
}

.breadcrumb .breadcrumb-item a {
  color: #3c4858;
}

.breadcrumb .breadcrumb-item a:hover {
  color: var(--marketplaceColor);
}

.breadcrumb .breadcrumb-item.active {
  color: var(--marketplaceColor);
}

.breadcrumb .breadcrumb-item:before {
  content: '';
}

.breadcrumb .breadcrumb-item:after {
  content: '\F0142' !important;
  font-size: 14px;
  color: #3c4858;
  font-family: 'Material Design Icons';
  padding-left: 5px;
}

.breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pagination .page-item .page-link {
  color: #8492a6;
  border: 1px solid #dee2e6;
  font-size: 14px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  color: var(--marketplaceColor);
  background: #f8f9fc;
  border-color: #dee2e6;
}

.pagination .page-item.active .page-link {
  color: var(--marketplaceColor);
  background: #f8f9fc;
  border-color: #dee2e6;
  cursor: not-allowed;
}

.avatar.avatar-coin,
.avatar.avatar-sm-sm {
  height: 36px;
  width: 36px;
}

.avatar.avatar-wallet {
  height: 80px;
  width: 80px;
}

@media (max-width: 768px) {
  .avatar.avatar-wallet {
    height: 50px;
    width: 50px;
  }
}

.avatar.avatar-ex-sm {
  max-height: 25px;
}

.avatar.avatar-md-sm {
  height: 54px;
  width: 54px;
}

.avatar.avatar-small {
  height: 65px;
  width: 65px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.avatar.avatar-300 {
  height: 300px;
  width: 300px;
}

.accordion .accordion-item {
  overflow: hidden;
  border: 0;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  background: transparent;
}

.accordion .accordion-item .accordion-button {
  font-weight: 500 !important;
  text-align: left;
  box-shadow: none;
  padding: 1rem 2.5rem 1rem 1.25rem;
}

.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.accordion .accordion-item .accordion-button:after {
  position: absolute;
  content: '-';
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: var(--marketplaceColor);
  right: 20px;
  transition: all 0.5s ease;
  font-size: 20px;
}

.accordion .accordion-item .accordion-button.collapsed {
  background-color: #ffffff;
  transition: all 0.5s;
}

.accordion .accordion-item .accordion-button.collapsed:after {
  content: '+';
  width: 20px;
  height: 20px;
  right: 10px;
  line-height: 20px;
  color: #3c4858 !important;
}

.accordion .accordion-item .accordion-button:after {
  background-image: none;
}

.accordion .accordion-item .accordion-button:not(.collapsed) {
  color: var(--marketplaceColor);
  box-shadow: none;
}

.progress-box .title {
  font-size: 15px;
}

.progress-box .progress {
  height: 8px;
  overflow: visible;
}

.progress-box .progress .progress-bar {
  border-radius: 5px;
  -webkit-animation: animate-positive 3s;
  animation: animate-positive 3s;
  overflow: visible !important;
}

.progress-box .progress .progress-value {
  position: absolute;
  top: -32px;
  right: -15px;
  font-size: 13px;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.form-label,
.form-check-label {
  font-size: 14px;
}

.form-control {
  border: 1px solid #e9ecef;
  font-size: 14px;
  line-height: 26px;
  background-color: #ffffff;
  border-radius: 6px;
}

.form-control:focus {
  border-color: #dee2e6;
  box-shadow: none;
}

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control:disabled {
  background-color: #dee2e6;
}

.form-check-input {
  border: 1px solid #e9ecef;
}

.form-check-input:focus {
  border-color: var(--marketplaceColor);
  box-shadow: none;
}

.form-check-input.form-check-input:checked {
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.form-icon .icons {
  position: absolute;
  top: 15px;
  left: 18px;
}

.selectr-selected {
  border-radius: 5px;
  padding: 10.5px 28px 10.5px 14px;
}

.selectr-selected,
.selectr-options-container,
.selectr-selected,
.selectr-option.active,
.selectr-option.selected,
.selectr-input {
  border: 1px solid #e9ecef !important;
}

.watchlist .selectr-container {
  width: 150px !important;
}

.selectr-container.open .selectr-selected {
  border-color: #e9ecef #e9ecef transparent;
  border-radius: 5px 5px 0 0;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.map {
  line-height: 0;
}

.map iframe {
  width: 100%;
  height: 400px;
}

.nav-pills {
  margin-bottom: 15px;
  background: #f4f6fa;
}

.nav-pills .nav-link {
  color: #495057 !important;
  padding: 5px;
  transition: all 0.5s ease;
}

.nav-pills .nav-link.nav-link-alt {
  color: #ffffff !important;
}

.nav-pills .nav-link.nav-link-alt.active {
  background: #3c4858 !important;
}

.nav-pills .nav-link.active {
  background: var(--marketplaceColor);
  color: #ffffff !important;
}

.nav-pills .nav-link.active .tab-para {
  color: rgba(255, 255, 255, 0.65) !important;
}

.nav-tabs .nav-link {
  border: 0px;
  color: #8492a6;
  font-weight: 500;
}

.nav-tabs .nav-link:hover {
  color: rgba(16, 102, 231, 0.8);
}

.nav-tabs .nav-link.active {
  color: var(--marketplaceColor);
  border-bottom: 2px solid var(--marketplaceColor);
}

.subcribe-form .btn {
  padding: 8px 20px;
}

.subcribe-form input {
  padding: 12px 20px 12px 16px;
  width: 100%;
  color: #3c4858 !important;
  border: none;
  outline: none !important;
  padding-right: 160px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 45px;
}

.subcribe-form button {
  position: absolute;
  top: 2px;
  right: 2px;
  outline: none !important;
}

.subcribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .table td,
.table-responsive .table th {
  vertical-align: middle;
  border-color: #f8f9fa !important;
}

.table-responsive .table.table-nowrap th,
.table-responsive .table.table-nowrap td {
  white-space: nowrap;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #3c4858;
  background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
  text-align: end;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--marketplaceColor);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.social-icon li a {
  color: #3c4858;
  border: 1px solid #3c4858;
  display: inline-flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.social-icon li a .fea-social {
  stroke-width: 2;
}

.social-icon li a:hover {
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor) !important;
  color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
  fill: var(--marketplaceColor);
}

.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.social-icon.foot-social-icon li a {
  color: #adb5bd;
  border-color: #283353;
}

.back-to-top {
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  display: none;
  transition: all 0.5s ease;
  height: 36px;
  width: 36px;
  line-height: 34px;
  text-align: center;
  background: var(--marketplaceColor);
  color: #ffffff !important;
}

.back-to-top .icons {
  transition: all 0.5s ease;
}

.back-to-home {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1;
}

.sticky-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.fea {
  stroke-width: 1.8;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^='uil-']:before,
[class*=' uil-']:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

/*********************************/
/*         Helpers               */
/*===============================*/
.section {
  padding: 100px 0;
  position: relative;
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.section-two {
  padding: 60px 0;
  position: relative;
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.65);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-white {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-linear-gradient {
  background: linear-gradient(
    to bottom,
    rgba(22, 28, 45, 0) 0%,
    rgba(22, 28, 45, 0) 50%,
    rgba(22, 28, 45, 0.3) 80%,
    #161c2d 100%
  );
}

.bg-linear-gradient-2 {
  background: linear-gradient(
    to bottom,
    rgba(22, 28, 45, 0) 0%,
    rgba(22, 28, 45, 0.3) 50%,
    rgba(22, 28, 45, 0.7) 75%,
    #161c2d 100%
  );
}

.bg-linear-gradient-3 {
  background: linear-gradient(
    to right,
    #161c2d 0%,
    #161c2d 25%,
    #161c2d 50%,
    rgba(22, 28, 45, 0.5) 75%,
    rgba(22, 28, 45, 0) 100%
  );
}

.bg-gradient-overlay {
  background: linear-gradient(
    to bottom,
    rgba(22, 28, 45, 0) 0%,
    rgba(22, 28, 45, 0.5) 25%,
    rgba(22, 28, 45, 0.75) 50%,
    #161c2d 100%
  );
}

.bg-gradient-overlay-2 {
  background: linear-gradient(
    to bottom,
    rgba(22, 28, 45, 0.5) 0%,
    rgba(22, 28, 45, 0.6) 25%,
    rgba(22, 28, 45, 0.7) 50%,
    rgba(22, 28, 45, 0.8) 100%
  );
}

.bg-primary-gradient-overlay {
  background: linear-gradient(
    to bottom,
    rgba(16, 102, 231, 0) 0%,
    rgba(16, 102, 231, 0.25) 25%,
    rgba(16, 102, 231, 0.5) 50%,
    rgba(16, 102, 231, 0.75) 75%,
    var(--marketplaceColor) 100%
  );
}

.bg-gradient-white-overlay {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.titleHeading {
  line-height: 26px;
}

.titleHeading .heading {
  font-size: 45px !important;
  letter-spacing: 1px;
}

.titleHeading .heading.sub-heading {
  font-size: 32px !important;
}

@media (max-width: 768px) {
  .titleHeading .heading.sub-heading {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .titleHeading .heading {
    font-size: 36px !important;
  }
}

.titleHeading .para-desc {
  font-size: 17px;
}

.sectionTitle .title {
  font-size: 28px !important;
}

@media (max-width: 768px) {
  .sectionTitle .title {
    font-size: 24px !important;
  }
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.text-slider {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.text-slider > ul {
  white-space: nowrap;
}

.text-slider .move-text {
  -webkit-animation: moveSlideshow 12s linear infinite;
  animation: moveSlideshow 12s linear infinite;
}

@-webkit-keyframes moveSlideshow {
  100% {
    transform: translateX(-45%);
  }
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-45%);
  }
}

.mover {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.img-skewed {
  transform: perspective(650px) rotateX(0deg) rotateY(-10deg);
}

.tobii__btn svg {
  height: 30px;
  width: auto;
}

.tobii__counter {
  font-size: 16px;
}

.tobii-zoom {
  display: block !important;
}

.tobii-zoom__icon {
  display: none;
}

.play-icon {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  left: 0;
  text-align: center;
}

.play-icon i {
  height: 75px;
  width: 75px;
  font-size: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.play-btn {
  height: 78px;
  width: 78px;
  font-size: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.play-btn .icons {
  -webkit-text-stroke: 2px var(--marketplaceColor);
  -webkit-text-fill-color: transparent;
}

.play-btn .icons:hover {
  -webkit-text-stroke: 2px var(--marketplaceColor);
  -webkit-text-fill-color: var(--marketplaceColor);
}

#grid {
  padding: 0 !important;
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}

.position-middle-bottom {
  position: absolute;
  bottom: 15px;
  left: 12px;
  right: 12px;
  text-align: center;
}

.app-subscribe {
  position: absolute;
  top: -150px;
}

@media (max-width: 767px) {
  .app-subscribe {
    position: relative;
    top: 0;
  }
}

.features-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.features-absolute {
  margin: -200px 0 0px;
}

@media (max-width: 768px) {
  .features-absolute {
    margin: -140px 0 0;
  }
}

.features-absolute.blog-search {
  margin: -120px 0 0px;
}

@media (max-width: 768px) {
  .features-absolute.blog-search {
    margin: -90px 0 0px;
  }
}

.ribbon.ribbon-primary span {
  background-color: var(--marketplaceColor) !important;
}

.ribbon.ribbon-primary span:before {
  border-left-color: var(--marketplaceColorDark);
  border-top-color: var(--marketplaceColorDark);
}

.ribbon.ribbon-primary span:after {
  border-right-color: var(--marketplaceColorDark);
  border-top-color: var(--marketplaceColorDark);
}

.ribbon.ribbon-secondary span {
  background-color: var(--marketplaceColorLight) !important;
}

.ribbon.ribbon-secondary span:before {
  border-left-color: #465571;
  border-top-color: #465571;
}

.ribbon.ribbon-secondary span:after {
  border-right-color: #465571;
  border-top-color: #465571;
}

.ribbon.ribbon-success span {
  background-color: #1cb59f !important;
}

.ribbon.ribbon-success span:before {
  border-left-color: #158978;
  border-top-color: #158978;
}

.ribbon.ribbon-success span:after {
  border-right-color: #158978;
  border-top-color: #158978;
}

.ribbon.ribbon-warning span {
  background-color: #fa693e !important;
}

.ribbon.ribbon-warning span:before {
  border-left-color: #f9420c;
  border-top-color: #f9420c;
}

.ribbon.ribbon-warning span:after {
  border-right-color: #f9420c;
  border-top-color: #f9420c;
}

.ribbon.ribbon-info span {
  background-color: #17a2b8 !important;
}

.ribbon.ribbon-info span:before {
  border-left-color: #117a8b;
  border-top-color: #117a8b;
}

.ribbon.ribbon-info span:after {
  border-right-color: #117a8b;
  border-top-color: #117a8b;
}

.ribbon.ribbon-danger span {
  background-color: #d93030 !important;
}

.ribbon.ribbon-danger span:before {
  border-left-color: #b52121;
  border-top-color: #b52121;
}

.ribbon.ribbon-danger span:after {
  border-right-color: #b52121;
  border-top-color: #b52121;
}

.ribbon.ribbon-dark span {
  background-color: #3c4858 !important;
}

.ribbon.ribbon-dark span:before {
  border-left-color: #272f3a;
  border-top-color: #272f3a;
}

.ribbon.ribbon-dark span:after {
  border-right-color: #272f3a;
  border-top-color: #272f3a;
}

.ribbon.ribbon-muted span {
  background-color: #8492a6 !important;
}

.ribbon.ribbon-muted span:before {
  border-left-color: #68788f;
  border-top-color: #68788f;
}

.ribbon.ribbon-muted span:after {
  border-right-color: #68788f;
  border-top-color: #68788f;
}

.ribbon.ribbon-light span {
  background-color: #f8f9fc !important;
}

.ribbon.ribbon-light span:before {
  border-left-color: #d4daed;
  border-top-color: #d4daed;
}

.ribbon.ribbon-light span:after {
  border-right-color: #d4daed;
  border-top-color: #d4daed;
}

.ribbon.ribbon-footer span {
  background-color: #202942 !important;
}

.ribbon.ribbon-footer span:before {
  border-left-color: #0f131f;
  border-top-color: #0f131f;
}

.ribbon.ribbon-footer span:after {
  border-right-color: #0f131f;
  border-top-color: #0f131f;
}

.ribbon {
  position: absolute;
  top: -6px;
  width: 75px;
  height: 75px;
  z-index: 2;
}

.ribbon.ribbon-right {
  right: -4px;
}

.ribbon.ribbon-right span {
  right: -23px;
  transform: rotate(45deg);
}

.ribbon.ribbon-left {
  left: -5px;
}

.ribbon.ribbon-left span {
  left: -21px;
  transform: rotate(-45deg);
}

.ribbon span {
  position: absolute;
  top: 18px;
  width: 100px;
  color: #ffffff;
}

.ribbon span:before,
.ribbon span:after {
  content: '';
  position: absolute;
  top: 100%;
  z-index: -1;
  border: 3px solid transparent;
}

.ribbon span:before {
  left: 0;
}

.ribbon span:after {
  right: 0;
}

.round-effect .primary-round,
.round-effect .gradient-round {
  position: fixed;
  width: 650px;
  height: 650px;
  border-radius: 50%;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  pointer-events: none;
  z-index: -1;
}

.round-effect .primary-round {
  top: 5%;
  left: 0;
  background-color: var(--marketplaceColor);
}

.round-effect .gradient-round {
  bottom: 5%;
  right: 0;
  background-color: #ffa432;
}

.background-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: 0;
}

.background-lines .line {
  background: rgba(16, 102, 231, 0.1);
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  overflow: hidden;
}

.background-lines .line:after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(16, 102, 231, 0.3) 75%,
    rgba(16, 102, 231, 0.3) 100%
  );
  -webkit-animation: run 7s 0s infinite;
  animation: run 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.background-lines .line:nth-child(1) {
  margin-left: -25%;
}

.background-lines .line:nth-child(3) {
  margin-left: 25%;
}

.background-lines .line:nth-child(1):after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.background-lines .line:nth-child(3):after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

@-webkit-keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 100%;
  }
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 100%;
  }
}

.zoom-image {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}

.image-wrap {
  position: absolute;
  top: -350px;
  bottom: -350px;
  left: -100px;
  right: -100px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: auto;
  -webkit-animation: 100s ppb_kenburns linear infinite alternate;
  animation: 100s ppb_kenburns linear infinite alternate;
}

@-webkit-keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}

@keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: transparent;
  border: 0;
  transition: all 0.5s ease;
}

#topnav .logo {
  float: left;
  color: #3c4858 !important;
}

#topnav .logo .l-dark,
#topnav .logo .logo-dark-mode {
  display: none;
}

#topnav .logo .l-light,
#topnav .logo .logo-light-mode {
  display: inline-block;
}

#topnav .logo:focus {
  outline: none;
}

#topnav .has-submenu.active a {
  color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
  color: var(--marketplaceColor) !important;
}

#topnav .has-submenu.active.active .menu-arrow {
  border-color: var(--marketplaceColor);
}

#topnav .has-submenu {
  position: relative;
}

#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 14px;
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: var(--marketplaceColor);
}

#topnav .navbar-toggle {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 30px 0 26px 10px;
  height: 18px;
}

#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #3c4858;
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}

#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
  background-color: var(--marketplaceColor);
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
  background-color: transparent;
}

#topnav .buy-button {
  float: right;
  line-height: 68px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#topnav .buy-button > li {
  line-height: initial;
}

#topnav .buy-button .btn-icon-dark {
  display: none;
}

#topnav .buy-button .btn-icon-light {
  display: inline-block;
}

#topnav .buy-button .nav-light-icon-dark {
  display: none;
}

#topnav .buy-button .nav-light-icon-white {
  display: inline-block;
}

#topnav .buy-button .search-bar .menu-search form {
  position: relative;
}

#topnav .buy-button .search-bar .searchform input[type='text'] {
  box-shadow: none;
  padding: 10px 12px;
  height: 44px;
  font-size: 14px;
  display: block;
  outline: none !important;
  padding-right: 45px;
}

#topnav .buy-button .search-bar .searchform input[type='submit'] {
  display: none;
}

#topnav .buy-button .search-bar .searchform:after {
  content: '\F0349';
  position: absolute;
  font-family: 'Material Design Icons';
  right: 14px;
  top: 14px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}

#topnav .buy-button .dropdown .dropdown-toggle:after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
  display: none;
}

#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#topnav .navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
}

#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  color: var(--marketplaceColor) !important;
}

#topnav .navigation-menu > li > a {
  display: block;
  color: #3c4858;
  font-size: 15px;
  background-color: transparent !important;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  transition: all 0.5s;
  font-family: 'Manrope', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  color: var(--marketplaceColor);
}

#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 14px;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #3c4858 !important;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.5s;
  right: -5px;
  top: 33px;
}

#topnav .menu-extras {
  float: right;
}

#topnav.scroll {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
  color: #3c4858;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #3c4858;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  color: var(--marketplaceColor);
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: var(--marketplaceColor);
}

#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}

#topnav.nav-sticky {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.nav-sticky.gradient {
  background: rgba(255, 255, 255, 0.9) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #3c4858;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: var(--marketplaceColor) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: var(--marketplaceColor) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: var(--marketplaceColor) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #3c4858;
}

#topnav.nav-sticky.tagline-height {
  top: 0 !important;
}

#topnav.nav-sticky .buy-button .btn-icon-dark {
  display: inline-block;
}

#topnav.nav-sticky .buy-button .btn-icon-light {
  display: none;
}

#topnav.nav-sticky .buy-button .nav-light-icon-dark {
  display: inline-block;
}

#topnav.nav-sticky .buy-button .nav-light-icon-white {
  display: none;
}

#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  margin-right: 15px;
  padding: 0px 0px 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1116px !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow,
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 33px !important;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 101%;
    left: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    transition: all 0.2s ease;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 600;
    color: #3c4858 !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: var(--marketplaceColor) !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    top: auto;
    display: flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    vertical-align: top;
    width: 20%;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 10px 20px;
    white-space: nowrap;
    letter-spacing: 0.04em;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 101%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: var(--marketplaceColor);
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: var(--marketplaceColor) !important;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, 0.75);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, 0.75);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-right {
    justify-content: flex-end !important;
  }
  #topnav .navigation-menu.nav-right > li:last-child {
    margin-right: 0 !important;
  }
  #topnav .navigation-menu.nav-right > li:last-child > a {
    padding-right: 0 !important;
  }
  #topnav .navigation-menu.nav-right > li:last-child .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu.nav-right > li:last-child .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav .navigation-menu.nav-right > li:last-child .submenu > li.has-submenu .submenu {
    left: auto;
    right: 101%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navigation-menu.nav-left {
    justify-content: flex-start !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: 0 !important;
    right: auto !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    left: 45px !important;
    right: auto !important;
  }
  #topnav.gradient {
    background: linear-gradient(to right, var(--marketplaceColor) 0%, #ffa432 100%);
  }
  #topnav .buy-button {
    padding-left: 15px;
    margin-left: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  #topnav {
    background-color: #ffffff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    min-height: 74px;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #3c4858 !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 7px 15px;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #3c4858;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    color: var(--marketplaceColor);
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .buy-button {
    padding: 3px 0;
  }
  #topnav .buy-button .btn-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .btn-icon-light {
    display: none;
  }
  #topnav .buy-button .nav-light-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .nav-light-icon-white {
    display: none;
  }
  #topnav.gradient {
    background: #ffffff !important;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: var(--marketplaceColor);
  }
  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f1f3f9;
    border-bottom: 1px solid #f1f3f9;
    background-color: #ffffff;
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 16px;
    top: 16px;
  }
}

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-toggle {
    display: block;
  }
}

@media (max-width: 375px) {
  #topnav .buy-menu-btn {
    display: block !important;
    margin: 0 10px;
    padding: 10px 20px;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}

.tagline {
  position: absolute;
  width: 100%;
  z-index: 99;
  font-size: 14px;
  padding: 13px 0;
}

@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .tagline-height {
    top: 0px !important;
  }
}

@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}

.sidebar-nav {
  padding: 15px 0;
}

.sidebar-nav > .navbar-item {
  padding: 3px 15px;
}

.sidebar-nav > .navbar-item .navbar-link {
  color: #3c4858 !important;
  font-size: 15px;
  font-weight: 600;
}

.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  font-size: 18px;
  margin-right: 6px;
}

#navmenu-nav li.active a {
  color: var(--marketplaceColor) !important;
}

#navmenu-nav li.account-menu.active .navbar-link,
#navmenu-nav li.account-menu:hover .navbar-link {
  color: #ffffff !important;
  background-color: var(--marketplaceColor) !important;
}

/*********************************/
/*         Home & Hero           */
/*===============================*/
.bg-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-home-75vh {
  height: 75vh !important;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

@media (max-width: 767px) {
  .bg-home-75vh {
    height: 80vh !important;
  }
}

.bg-half-260 {
  padding: 260px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-170 {
  padding: 170px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-100 {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half {
  padding: 184px 0 90px;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-creator-profile,
.bg-item-detail,
.bg-half-174 {
  padding: 174px 0 100px;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-auth-home {
  padding: 142px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-page {
  padding: 150px 0 100px;
  position: relative;
}

@media (max-width: 767px) {
  .bg-half-page {
    padding: 100px 0 60px;
  }
}

.home-dashboard img {
  position: relative;
  top: 60px;
  z-index: 1;
}

@media (min-width: 768px) {
  .home-dashboard.onepage-hero {
    overflow: hidden;
  }
}

.nft-margin {
  margin-top: 74px;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}

.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}

@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

@media (max-width: 768px) {
  .bg-half {
    padding: 169px 0 50px;
  }
}

@media (max-width: 767px) {
  .bg-home,
  .bg-half-170,
  .bg-half-260,
  .bg-marketing,
  .swiper-slider-hero .swiper-container .swiper-slide {
    padding: 150px 0;
    height: auto;
  }
}

.bg-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.bg-video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*********************************/
/*         Features              */
/*===============================*/
.features.feature-primary .icons i {
  background: rgba(16, 102, 231, 0.1);
  color: var(--marketplaceColor);
}

.features.feature-primary .title:hover {
  color: var(--marketplaceColor) !important;
}

.features.feature-primary .fea-icon,
.features.feature-primary .link {
  color: var(--marketplaceColor) !important;
}

.features.feature-secondary .icons i {
  background: rgba(90, 109, 144, 0.1);
  color: var(--marketplaceColorLight);
}

.features.feature-secondary .title:hover {
  color: var(--marketplaceColorLight) !important;
}

.features.feature-secondary .fea-icon,
.features.feature-secondary .link {
  color: var(--marketplaceColorLight) !important;
}

.features.feature-success .icons i {
  background: rgba(28, 181, 159, 0.1);
  color: #1cb59f;
}

.features.feature-success .title:hover {
  color: #1cb59f !important;
}

.features.feature-success .fea-icon,
.features.feature-success .link {
  color: #1cb59f !important;
}

.features.feature-warning .icons i {
  background: rgba(250, 105, 62, 0.1);
  color: #fa693e;
}

.features.feature-warning .title:hover {
  color: #fa693e !important;
}

.features.feature-warning .fea-icon,
.features.feature-warning .link {
  color: #fa693e !important;
}

.features.feature-info .icons i {
  background: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

.features.feature-info .title:hover {
  color: #17a2b8 !important;
}

.features.feature-info .fea-icon,
.features.feature-info .link {
  color: #17a2b8 !important;
}

.features.feature-danger .icons i {
  background: rgba(217, 48, 48, 0.1);
  color: #d93030;
}

.features.feature-danger .title:hover {
  color: #d93030 !important;
}

.features.feature-danger .fea-icon,
.features.feature-danger .link {
  color: #d93030 !important;
}

.features.feature-dark .icons i {
  background: rgba(60, 72, 88, 0.1);
  color: #3c4858;
}

.features.feature-dark .title:hover {
  color: #3c4858 !important;
}

.features.feature-dark .fea-icon,
.features.feature-dark .link {
  color: #3c4858 !important;
}

.features.feature-muted .icons i {
  background: rgba(132, 146, 166, 0.1);
  color: #8492a6;
}

.features.feature-muted .title:hover {
  color: #8492a6 !important;
}

.features.feature-muted .fea-icon,
.features.feature-muted .link {
  color: #8492a6 !important;
}

.features.feature-light .icons i {
  background: rgba(248, 249, 252, 0.1);
  color: #f8f9fc;
}

.features.feature-light .title:hover {
  color: #f8f9fc !important;
}

.features.feature-light .fea-icon,
.features.feature-light .link {
  color: #f8f9fc !important;
}

.features.feature-footer .icons i {
  background: rgba(32, 41, 66, 0.1);
  color: #202942;
}

.features.feature-footer .title:hover {
  color: #202942 !important;
}

.features.feature-footer .fea-icon,
.features.feature-footer .link {
  color: #202942 !important;
}

.features {
  transition: all 0.5s ease;
}

.features.feature-clean .icons i {
  height: 85px;
  width: 85px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/*********************************/
/*         Creator               */
/*===============================*/
.creators.creator-primary .name:hover {
  color: var(--marketplaceColor) !important;
}

.creators.creator-secondary .name:hover {
  color: var(--marketplaceColorLight) !important;
}

.creators.creator-success .name:hover {
  color: #1cb59f !important;
}

.creators.creator-warning .name:hover {
  color: #fa693e !important;
}

.creators.creator-info .name:hover {
  color: #17a2b8 !important;
}

.creators.creator-danger .name:hover {
  color: #d93030 !important;
}

.creators.creator-dark .name:hover {
  color: #3c4858 !important;
}

.creators.creator-muted .name:hover {
  color: #8492a6 !important;
}

.creators.creator-light .name:hover {
  color: #f8f9fc !important;
}

.creators.creator-footer .name:hover {
  color: #202942 !important;
}

.creators.creators-two {
  transition: all 0.5s ease;
}

.creators.creators-two:hover {
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.creators .verified i {
  font-size: 20px;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.creators .online i {
  position: absolute;
  top: 3px;
  left: 0px;
  font-size: 12px;
}

.users.user-primary .name:hover {
  color: var(--marketplaceColor) !important;
}

.users.user-secondary .name:hover {
  color: var(--marketplaceColorLight) !important;
}

.users.user-success .name:hover {
  color: #1cb59f !important;
}

.users.user-warning .name:hover {
  color: #fa693e !important;
}

.users.user-info .name:hover {
  color: #17a2b8 !important;
}

.users.user-danger .name:hover {
  color: #d93030 !important;
}

.users.user-dark .name:hover {
  color: #3c4858 !important;
}

.users.user-muted .name:hover {
  color: #8492a6 !important;
}

.users.user-light .name:hover {
  color: #f8f9fc !important;
}

.users.user-footer .name:hover {
  color: #202942 !important;
}

.users .user-item {
  opacity: 0.5;
}

.users .user-item:hover {
  opacity: 1;
}

.profile-pic,
.profile-banner {
  position: relative;
  color: transparent;
}

.profile-pic input,
.profile-banner input {
  display: none;
}

.profile-pic .icons,
.profile-banner .icons {
  cursor: pointer;
  z-index: 1;
}

.profile-banner img {
  width: 1200px;
  height: 200px;
}

.profile-edit .form-control {
  border-bottom: 1px solid #e9ecef !important;
  border-radius: 0;
  border: 0;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
}

.preview-box img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

/*********************************/
/*         Explore Items         */
/*===============================*/
.nftItems.nftPrimary .title:hover,
.nftItems.nftPrimary .creator-name:hover {
  color: var(--marketplaceColor) !important;
}

.nftItems.nftPrimary .rate {
  color: var(--marketplaceColor) !important;
}

.nftItems.nftPrimary .btn {
  background-color: var(--marketplaceColor) !important;
  border: 1px solid var(--marketplaceColor) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(16, 102, 231, 0.1);
}

.nftItems.nftPrimary .btn:hover,
.nftItems.nftPrimary .btn:focus,
.nftItems.nftPrimary .btn:active,
.nftItems.nftPrimary .btn.active,
.nftItems.nftPrimary .btn.focus {
  background-color: var(--marketplaceColorDark) !important;
  border-color: var(--marketplaceColorDark) !important;
  color: #ffffff !important;
}

.nftItems.nft-secondary .title:hover,
.nftItems.nft-secondary .creator-name:hover {
  color: var(--marketplaceColorLight) !important;
}

.nftItems.nft-secondary .rate {
  color: var(--marketplaceColorLight) !important;
}

.nftItems.nft-secondary .btn {
  background-color: var(--marketplaceColorLight) !important;
  border: 1px solid var(--marketplaceColorLight) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.nftItems.nft-secondary .btn:hover,
.nftItems.nft-secondary .btn:focus,
.nftItems.nft-secondary .btn:active,
.nftItems.nft-secondary .btn.active,
.nftItems.nft-secondary .btn.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.nftItems.nft-success .title:hover,
.nftItems.nft-success .creator-name:hover {
  color: #1cb59f !important;
}

.nftItems.nft-success .rate {
  color: #1cb59f !important;
}

.nftItems.nft-success .btn {
  background-color: #1cb59f !important;
  border: 1px solid #1cb59f !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(28, 181, 159, 0.1);
}

.nftItems.nft-success .btn:hover,
.nftItems.nft-success .btn:focus,
.nftItems.nft-success .btn:active,
.nftItems.nft-success .btn.active,
.nftItems.nft-success .btn.focus {
  background-color: #158978 !important;
  border-color: #158978 !important;
  color: #ffffff !important;
}

.nftItems.nft-warning .title:hover,
.nftItems.nft-warning .creator-name:hover {
  color: #fa693e !important;
}

.nftItems.nft-warning .rate {
  color: #fa693e !important;
}

.nftItems.nft-warning .btn {
  background-color: #fa693e !important;
  border: 1px solid #fa693e !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(250, 105, 62, 0.1);
}

.nftItems.nft-warning .btn:hover,
.nftItems.nft-warning .btn:focus,
.nftItems.nft-warning .btn:active,
.nftItems.nft-warning .btn.active,
.nftItems.nft-warning .btn.focus {
  background-color: #f9420c !important;
  border-color: #f9420c !important;
  color: #ffffff !important;
}

.nftItems.nft-info .title:hover,
.nftItems.nft-info .creator-name:hover {
  color: #17a2b8 !important;
}

.nftItems.nft-info .rate {
  color: #17a2b8 !important;
}

.nftItems.nft-info .btn {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}

.nftItems.nft-info .btn:hover,
.nftItems.nft-info .btn:focus,
.nftItems.nft-info .btn:active,
.nftItems.nft-info .btn.active,
.nftItems.nft-info .btn.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.nftItems.nft-danger .title:hover,
.nftItems.nft-danger .creator-name:hover {
  color: #d93030 !important;
}

.nftItems.nft-danger .rate {
  color: #d93030 !important;
}

.nftItems.nft-danger .btn {
  background-color: #d93030 !important;
  border: 1px solid #d93030 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(217, 48, 48, 0.1);
}

.nftItems.nft-danger .btn:hover,
.nftItems.nft-danger .btn:focus,
.nftItems.nft-danger .btn:active,
.nftItems.nft-danger .btn.active,
.nftItems.nft-danger .btn.focus {
  background-color: #b52121 !important;
  border-color: #b52121 !important;
  color: #ffffff !important;
}

.nftItems.nft-dark .title:hover,
.nftItems.nft-dark .creator-name:hover {
  color: #3c4858 !important;
}

.nftItems.nft-dark .rate {
  color: #3c4858 !important;
}

.nftItems.nft-dark .btn {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.1);
}

.nftItems.nft-dark .btn:hover,
.nftItems.nft-dark .btn:focus,
.nftItems.nft-dark .btn:active,
.nftItems.nft-dark .btn.active,
.nftItems.nft-dark .btn.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.nftItems.nft-muted .title:hover,
.nftItems.nft-muted .creator-name:hover {
  color: #8492a6 !important;
}

.nftItems.nft-muted .rate {
  color: #8492a6 !important;
}

.nftItems.nft-muted .btn {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.1);
}

.nftItems.nft-muted .btn:hover,
.nftItems.nft-muted .btn:focus,
.nftItems.nft-muted .btn:active,
.nftItems.nft-muted .btn.active,
.nftItems.nft-muted .btn.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.nftItems.nft-light .title:hover,
.nftItems.nft-light .creator-name:hover {
  color: #f8f9fc !important;
}

.nftItems.nft-light .rate {
  color: #f8f9fc !important;
}

.nftItems.nft-light .btn {
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.1);
}

.nftItems.nft-light .btn:hover,
.nftItems.nft-light .btn:focus,
.nftItems.nft-light .btn:active,
.nftItems.nft-light .btn.active,
.nftItems.nft-light .btn.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.nftItems.nftFooter .title:hover,
.nftItems.nftFooter .creator-name:hover {
  color: #202942 !important;
}

.nftItems.nftFooter .rate {
  color: #202942 !important;
}

.nftItems.nftFooter .btn {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.nftItems.nftFooter .btn:hover,
.nftItems.nftFooter .btn:focus,
.nftItems.nftFooter .btn:active,
.nftItems.nftFooter .btn.active,
.nftItems.nftFooter .btn.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.imgFluid {
  max-width: 100%;
  height: auto;
}

.nftItems {
  transition: all 0.5s ease;
}
.nftItems .nftImage {
  position: relative !important;
  overflow: hidden !important;
}

.nftItems .nftImage img {
  transition: all 0.5s ease;
}

.nftItems .nftImage .icons {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.info {
  display: flex;
  flex-direction: row;
  padding: 16px 8px 8px 8px;
  background-color: white;
  text-align: left;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.skillInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.artistButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;

  @media (--viewportMedium) {
    /* display: block;
    width: 100px; */
  }
}

.nftItems .nftImage .bidBtn {
  position: absolute;
  bottom: 0;
  /* transform: translateY(-50%); */
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease;
}

.nftItems .nftImage:hover .icons,
.nftItems .nftImage:hover .bidBtn {
  opacity: 1;
}

.nftItems .like-icon {
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  background: rgba(16, 102, 231, 0.1);
  background-color: rgba(16, 102, 231, 0.1) !important;
  color: var(--marketplaceColor) !important;
  border-radius: 10px !important;
  font-size: 30px !important;
  line-height: 1.5;
  font-weight: 600;
}

.nftItems .like-icon .icon {
  -webkit-text-stroke: 1px #3c4858;
  -webkit-text-fill-color: transparent;
}

.nftItems .like-icon .icon:hover,
.nftItems .like-icon .icon:focus {
  -webkit-text-stroke: #d93030;
  -webkit-text-fill-color: #d93030;
}

.nftItems .content .img-group {
  position: absolute;
  top: -18px;
}

.nftItems:hover {
  margin-top: -5px;
}

.nftItems:hover.nftPMarginMinus {
  margin-top: 0 !important;
}

.nftItems:hover .nftImage img {
  transform: scale(1.1);
}

.nftItems:hover.img-skewed {
  margin-top: 0 !important;
}

.nftItems.nft-auction:hover {
  margin-top: 0 !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.img-group .user-avatar img {
  position: relative;
  transition: all 0.5s ease;
}

.img-group .user-avatar img:hover {
  z-index: 1;
  transform: scale(1.1);
}

/*********************************/
/*             Filter            */
/*===============================*/
.container-filter li {
  margin: 6px !important;
  letter-spacing: 0.8px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #8492a6 !important;
  padding: 3px 15px;
  border-radius: 10px;
  border: 1px solid transparent;
}

.container-filter li.active,
.container-filter li:hover {
  color: var(--marketplaceColor) !important;
  background-color: #ffffff;
  border-color: rgba(16, 102, 231, 0.5);
}

/*********************************/
/*         Collection            */
/*===============================*/
.collections.collection-primary .title:hover {
  color: var(--marketplaceColor) !important;
}

.collections.collection-secondary .title:hover {
  color: var(--marketplaceColorLight) !important;
}

.collections.collection-success .title:hover {
  color: #1cb59f !important;
}

.collections.collection-warning .title:hover {
  color: #fa693e !important;
}

.collections.collection-info .title:hover {
  color: #17a2b8 !important;
}

.collections.collection-danger .title:hover {
  color: #d93030 !important;
}

.collections.collection-dark .title:hover {
  color: #3c4858 !important;
}

.collections.collection-muted .title:hover {
  color: #8492a6 !important;
}

.collections.collection-light .title:hover {
  color: #f8f9fc !important;
}

.collections.collection-footer .title:hover {
  color: #202942 !important;
}

.collections .verified i {
  font-size: 24px;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.collections .online i {
  position: absolute;
  top: 3px;
  left: 0px;
  font-size: 12px;
}

/*********************************/
/*         Activity              */
/*===============================*/
.activity.activity-primary .title:hover {
  color: var(--marketplaceColor) !important;
}

.activity.activity-primary .link {
  color: var(--marketplaceColor) !important;
}

.activity.activity-secondary .title:hover {
  color: var(--marketplaceColorLight) !important;
}

.activity.activity-secondary .link {
  color: var(--marketplaceColorLight) !important;
}

.activity.activity-success .title:hover {
  color: #1cb59f !important;
}

.activity.activity-success .link {
  color: #1cb59f !important;
}

.activity.activity-warning .title:hover {
  color: #fa693e !important;
}

.activity.activity-warning .link {
  color: #fa693e !important;
}

.activity.activity-info .title:hover {
  color: #17a2b8 !important;
}

.activity.activity-info .link {
  color: #17a2b8 !important;
}

.activity.activity-danger .title:hover {
  color: #d93030 !important;
}

.activity.activity-danger .link {
  color: #d93030 !important;
}

.activity.activity-dark .title:hover {
  color: #3c4858 !important;
}

.activity.activity-dark .link {
  color: #3c4858 !important;
}

.activity.activity-muted .title:hover {
  color: #8492a6 !important;
}

.activity.activity-muted .link {
  color: #8492a6 !important;
}

.activity.activity-light .title:hover {
  color: #f8f9fc !important;
}

.activity.activity-light .link {
  color: #f8f9fc !important;
}

.activity.activity-footer .title:hover {
  color: #202942 !important;
}

.activity.activity-footer .link {
  color: #202942 !important;
}

/*********************************/
/*         Wallets               */
/*===============================*/
.wallet.wallet-primary .link {
  color: var(--marketplaceColor) !important;
}

.wallet.wallet-secondary .link {
  color: var(--marketplaceColorLight) !important;
}

.wallet.wallet-success .link {
  color: #1cb59f !important;
}

.wallet.wallet-warning .link {
  color: #fa693e !important;
}

.wallet.wallet-info .link {
  color: #17a2b8 !important;
}

.wallet.wallet-danger .link {
  color: #d93030 !important;
}

.wallet.wallet-dark .link {
  color: #3c4858 !important;
}

.wallet.wallet-muted .link {
  color: #8492a6 !important;
}

.wallet.wallet-light .link {
  color: #f8f9fc !important;
}

.wallet.wallet-footer .link {
  color: #202942 !important;
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.customer-testi {
  cursor: e-resize;
}

.customer-testi .content:before {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(50%);
  box-sizing: border-box;
  border: 8px solid #3c4858;
  border-color: transparent #ffffff #ffffff transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: 2px 2px 2px -1px rgba(60, 72, 88, 0.15);
}

.tns-nav {
  text-align: center;
  margin-top: 10px;
}

.tns-nav button {
  border: 4px solid rgba(16, 102, 231, 0.5) !important;
  border-radius: 2px;
  transition: all 0.5s ease;
  border: none;
  margin: 0 3px;
  padding: 1px;
}

.tns-nav button.tns-nav-active {
  border-color: var(--marketplaceColor) !important;
}

.tns-controls button[data-controls='prev'],
.tns-controls button[data-controls='next'] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  color: #3c4858;
  border: none;
  transition: all 0.5s ease;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(60, 72, 88, 0.15);
}

.tns-controls button[data-controls='prev']:hover,
.tns-controls button[data-controls='next']:hover {
  background: var(--marketplaceColor);
  color: #ffffff;
}

.tns-controls button[data-controls='prev'] {
  left: 0;
}

.tns-controls button[data-controls='next'] {
  right: 0;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-360px * 6));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-360px * 6));
  }
}

.slider,
.slider2 {
  margin: auto;
  overflow: hidden;
  position: relative;
}

.slider::before,
.slider::after,
.slider2::before,
.slider2::after {
  content: '';
  position: absolute;
  z-index: 2;
}

.slider::after,
.slider2::after {
  right: 0;
  top: 0;
  transform: rotateZ(360deg);
}

.slider::before,
.slider2::before {
  left: 0;
  top: 0;
}

.slider .slideTrack,
.slider2 .slideTrack {
  -webkit-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite;
  display: flex;
  width: calc(360px * 12);
}

.slider .slide,
.slider2 .slide {
  height: auto;
  width: 360px;
}

.slider2 .slideTrack {
  -webkit-animation: scroll 54s linear infinite;
  animation: scroll 54s linear infinite;
  width: calc(360px * 12);
}

/*********************************/
/*              Team             */
/*===============================*/
.team.team-primary .title:hover {
  color: var(--marketplaceColor) !important;
}

.team.team-primary .btn {
  background-color: var(--marketplaceColor) !important;
  border: 1px solid var(--marketplaceColor) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(16, 102, 231, 0.1);
}

.team.team-primary .btn:hover,
.team.team-primary .btn:focus,
.team.team-primary .btn:active,
.team.team-primary .btn.active,
.team.team-primary .btn.focus {
  background-color: var(--marketplaceColorDark) !important;
  border-color: var(--marketplaceColorDark) !important;
  color: #ffffff !important;
}

.team.team-secondary .title:hover {
  color: var(--marketplaceColorLight) !important;
}

.team.team-secondary .btn {
  background-color: var(--marketplaceColorLight) !important;
  border: 1px solid var(--marketplaceColorLight) !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.1);
}

.team.team-secondary .btn:hover,
.team.team-secondary .btn:focus,
.team.team-secondary .btn:active,
.team.team-secondary .btn.active,
.team.team-secondary .btn.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.team.team-success .title:hover {
  color: #1cb59f !important;
}

.team.team-success .btn {
  background-color: #1cb59f !important;
  border: 1px solid #1cb59f !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(28, 181, 159, 0.1);
}

.team.team-success .btn:hover,
.team.team-success .btn:focus,
.team.team-success .btn:active,
.team.team-success .btn.active,
.team.team-success .btn.focus {
  background-color: #158978 !important;
  border-color: #158978 !important;
  color: #ffffff !important;
}

.team.team-warning .title:hover {
  color: #fa693e !important;
}

.team.team-warning .btn {
  background-color: #fa693e !important;
  border: 1px solid #fa693e !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(250, 105, 62, 0.1);
}

.team.team-warning .btn:hover,
.team.team-warning .btn:focus,
.team.team-warning .btn:active,
.team.team-warning .btn.active,
.team.team-warning .btn.focus {
  background-color: #f9420c !important;
  border-color: #f9420c !important;
  color: #ffffff !important;
}

.team.team-info .title:hover {
  color: #17a2b8 !important;
}

.team.team-info .btn {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.1);
}

.team.team-info .btn:hover,
.team.team-info .btn:focus,
.team.team-info .btn:active,
.team.team-info .btn.active,
.team.team-info .btn.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.team.team-danger .title:hover {
  color: #d93030 !important;
}

.team.team-danger .btn {
  background-color: #d93030 !important;
  border: 1px solid #d93030 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(217, 48, 48, 0.1);
}

.team.team-danger .btn:hover,
.team.team-danger .btn:focus,
.team.team-danger .btn:active,
.team.team-danger .btn.active,
.team.team-danger .btn.focus {
  background-color: #b52121 !important;
  border-color: #b52121 !important;
  color: #ffffff !important;
}

.team.team-dark .title:hover {
  color: #3c4858 !important;
}

.team.team-dark .btn {
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.1);
}

.team.team-dark .btn:hover,
.team.team-dark .btn:focus,
.team.team-dark .btn:active,
.team.team-dark .btn.active,
.team.team-dark .btn.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.team.team-muted .title:hover {
  color: #8492a6 !important;
}

.team.team-muted .btn {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.1);
}

.team.team-muted .btn:hover,
.team.team-muted .btn:focus,
.team.team-muted .btn:active,
.team.team-muted .btn.active,
.team.team-muted .btn.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.team.team-light .title:hover {
  color: #f8f9fc !important;
}

.team.team-light .btn {
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.1);
}

.team.team-light .btn:hover,
.team.team-light .btn:focus,
.team.team-light .btn:active,
.team.team-light .btn.active,
.team.team-light .btn.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.team.team-footer .title:hover {
  color: #202942 !important;
}

.team.team-footer .btn {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.1);
}

.team.team-footer .btn:hover,
.team.team-footer .btn:focus,
.team.team-footer .btn:active,
.team.team-footer .btn.active,
.team.team-footer .btn.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.team img,
.team .team-social {
  transition: all 0.5s ease;
}

.team .team-social {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -50px;
}

.team:hover .team-image {
  overflow: hidden;
  position: relative;
}

.team:hover .team-image img {
  transform: scale(1.05);
}

.team:hover .team-image .team-social {
  bottom: 16px;
}

/*********************************/
/*         Blog                  */
/*===============================*/
.blog.blog-primary .tag {
  background: var(--marketplaceColor) !important;
}

.blog.blog-primary .tag.gradient {
  background: linear-gradient(to right, var(--marketplaceColor) 0%, #ffa432 100%) !important;
}

.blog.blog-primary .title:hover {
  color: var(--marketplaceColor) !important;
}

.blog.blog-primary .link {
  color: var(--marketplaceColor) !important;
}

.blog.blog-primary .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-primary .btn-link:hover,
.blog.blog-primary .btn-link:focus,
.blog.blog-primary .btn-link:active,
.blog.blog-primary .btn-link.active,
.blog.blog-primary .btn-link.focus,
.blog.blog-primary .btn-link:not(:disabled):not(.disabled):active {
  color: var(--marketplaceColor) !important;
}

.blog.blog-primary .btn-link:after {
  background-color: var(--marketplaceColor) !important;
}

.blog.blog-secondary .tag {
  background: var(--marketplaceColorLight) !important;
}

.blog.blog-secondary .tag.gradient {
  background: linear-gradient(to right, var(--marketplaceColorLight) 0%, #ffa432 100%) !important;
}

.blog.blog-secondary .title:hover {
  color: var(--marketplaceColorLight) !important;
}

.blog.blog-secondary .link {
  color: var(--marketplaceColorLight) !important;
}

.blog.blog-secondary .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-secondary .btn-link:hover,
.blog.blog-secondary .btn-link:focus,
.blog.blog-secondary .btn-link:active,
.blog.blog-secondary .btn-link.active,
.blog.blog-secondary .btn-link.focus,
.blog.blog-secondary .btn-link:not(:disabled):not(.disabled):active {
  color: var(--marketplaceColorLight) !important;
}

.blog.blog-secondary .btn-link:after {
  background-color: var(--marketplaceColorLight) !important;
}

.blog.blog-success .tag {
  background: #1cb59f !important;
}

.blog.blog-success .tag.gradient {
  background: linear-gradient(to right, #1cb59f 0%, #ffa432 100%) !important;
}

.blog.blog-success .title:hover {
  color: #1cb59f !important;
}

.blog.blog-success .link {
  color: #1cb59f !important;
}

.blog.blog-success .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-success .btn-link:hover,
.blog.blog-success .btn-link:focus,
.blog.blog-success .btn-link:active,
.blog.blog-success .btn-link.active,
.blog.blog-success .btn-link.focus,
.blog.blog-success .btn-link:not(:disabled):not(.disabled):active {
  color: #1cb59f !important;
}

.blog.blog-success .btn-link:after {
  background-color: #1cb59f !important;
}

.blog.blog-warning .tag {
  background: #fa693e !important;
}

.blog.blog-warning .tag.gradient {
  background: linear-gradient(to right, #fa693e 0%, #ffa432 100%) !important;
}

.blog.blog-warning .title:hover {
  color: #fa693e !important;
}

.blog.blog-warning .link {
  color: #fa693e !important;
}

.blog.blog-warning .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-warning .btn-link:hover,
.blog.blog-warning .btn-link:focus,
.blog.blog-warning .btn-link:active,
.blog.blog-warning .btn-link.active,
.blog.blog-warning .btn-link.focus,
.blog.blog-warning .btn-link:not(:disabled):not(.disabled):active {
  color: #fa693e !important;
}

.blog.blog-warning .btn-link:after {
  background-color: #fa693e !important;
}

.blog.blog-info .tag {
  background: #17a2b8 !important;
}

.blog.blog-info .tag.gradient {
  background: linear-gradient(to right, #17a2b8 0%, #ffa432 100%) !important;
}

.blog.blog-info .title:hover {
  color: #17a2b8 !important;
}

.blog.blog-info .link {
  color: #17a2b8 !important;
}

.blog.blog-info .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-info .btn-link:hover,
.blog.blog-info .btn-link:focus,
.blog.blog-info .btn-link:active,
.blog.blog-info .btn-link.active,
.blog.blog-info .btn-link.focus,
.blog.blog-info .btn-link:not(:disabled):not(.disabled):active {
  color: #17a2b8 !important;
}

.blog.blog-info .btn-link:after {
  background-color: #17a2b8 !important;
}

.blog.blog-danger .tag {
  background: #d93030 !important;
}

.blog.blog-danger .tag.gradient {
  background: linear-gradient(to right, #d93030 0%, #ffa432 100%) !important;
}

.blog.blog-danger .title:hover {
  color: #d93030 !important;
}

.blog.blog-danger .link {
  color: #d93030 !important;
}

.blog.blog-danger .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-danger .btn-link:hover,
.blog.blog-danger .btn-link:focus,
.blog.blog-danger .btn-link:active,
.blog.blog-danger .btn-link.active,
.blog.blog-danger .btn-link.focus,
.blog.blog-danger .btn-link:not(:disabled):not(.disabled):active {
  color: #d93030 !important;
}

.blog.blog-danger .btn-link:after {
  background-color: #d93030 !important;
}

.blog.blog-dark .tag {
  background: #3c4858 !important;
}

.blog.blog-dark .tag.gradient {
  background: linear-gradient(to right, #3c4858 0%, #ffa432 100%) !important;
}

.blog.blog-dark .title:hover {
  color: #3c4858 !important;
}

.blog.blog-dark .link {
  color: #3c4858 !important;
}

.blog.blog-dark .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-dark .btn-link:hover,
.blog.blog-dark .btn-link:focus,
.blog.blog-dark .btn-link:active,
.blog.blog-dark .btn-link.active,
.blog.blog-dark .btn-link.focus,
.blog.blog-dark .btn-link:not(:disabled):not(.disabled):active {
  color: #3c4858 !important;
}

.blog.blog-dark .btn-link:after {
  background-color: #3c4858 !important;
}

.blog.blog-muted .tag {
  background: #8492a6 !important;
}

.blog.blog-muted .tag.gradient {
  background: linear-gradient(to right, #8492a6 0%, #ffa432 100%) !important;
}

.blog.blog-muted .title:hover {
  color: #8492a6 !important;
}

.blog.blog-muted .link {
  color: #8492a6 !important;
}

.blog.blog-muted .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-muted .btn-link:hover,
.blog.blog-muted .btn-link:focus,
.blog.blog-muted .btn-link:active,
.blog.blog-muted .btn-link.active,
.blog.blog-muted .btn-link.focus,
.blog.blog-muted .btn-link:not(:disabled):not(.disabled):active {
  color: #8492a6 !important;
}

.blog.blog-muted .btn-link:after {
  background-color: #8492a6 !important;
}

.blog.blog-light .tag {
  background: #f8f9fc !important;
}

.blog.blog-light .tag.gradient {
  background: linear-gradient(to right, #f8f9fc 0%, #ffa432 100%) !important;
}

.blog.blog-light .title:hover {
  color: #f8f9fc !important;
}

.blog.blog-light .link {
  color: #f8f9fc !important;
}

.blog.blog-light .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-light .btn-link:hover,
.blog.blog-light .btn-link:focus,
.blog.blog-light .btn-link:active,
.blog.blog-light .btn-link.active,
.blog.blog-light .btn-link.focus,
.blog.blog-light .btn-link:not(:disabled):not(.disabled):active {
  color: #f8f9fc !important;
}

.blog.blog-light .btn-link:after {
  background-color: #f8f9fc !important;
}

.blog.blog-footer .tag {
  background: #202942 !important;
}

.blog.blog-footer .tag.gradient {
  background: linear-gradient(to right, #202942 0%, #ffa432 100%) !important;
}

.blog.blog-footer .title:hover {
  color: #202942 !important;
}

.blog.blog-footer .link {
  color: #202942 !important;
}

.blog.blog-footer .btn-link {
  padding: 0 !important;
  color: #3c4858;
  position: relative;
}

.blog.blog-footer .btn-link:hover,
.blog.blog-footer .btn-link:focus,
.blog.blog-footer .btn-link:active,
.blog.blog-footer .btn-link.active,
.blog.blog-footer .btn-link.focus,
.blog.blog-footer .btn-link:not(:disabled):not(.disabled):active {
  color: #202942 !important;
}

.blog.blog-footer .btn-link:after {
  background-color: #202942 !important;
}

.blog {
  transition: all 0.5s ease;
}

.blog .tag {
  position: absolute;
  top: -10px;
}

.blog .tag:hover {
  color: #ffffff !important;
}

.blog .like-icon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .like-icon .icon {
  -webkit-text-stroke: 1px #3c4858;
  -webkit-text-fill-color: transparent;
}

.blog .like-icon .icon:hover,
.blog .like-icon .icon:focus {
  -webkit-text-stroke: #d93030;
  -webkit-text-fill-color: #d93030;
}

.sidebar .widget .title:hover {
  color: var(--marketplaceColor) !important;
}

.sidebar .widget .widget-search form {
  position: relative;
}

.sidebar .widget .widget-search input[type='text'],
.sidebar .widget .searchform input[type='text'] {
  box-shadow: none;
  padding: 12px 15px;
  height: 45px;
  font-size: 14px;
  display: block;
  width: 100%;
  outline: none !important;
  padding-right: 45px;
}

.sidebar .widget .widget-search input[type='submit'],
.sidebar .widget .searchform input[type='submit'] {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  width: 40px;
  height: 40px;
}

.sidebar .widget .widget-search .searchform:after {
  content: '\F0349';
  position: absolute;
  font-family: 'Material Design Icons';
  right: 16px;
  top: 15px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}

.sidebar .widget .widget-title {
  font-weight: 500;
}

.tagcloud > a {
  background: #f8f9fa;
  color: #3c4858;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 700;
}

.tagcloud > a:hover {
  background: var(--marketplaceColor);
  color: #ffffff;
}

/*********************************/
/*         Contact us            */
/*===============================*/
.error {
  margin: 8px 0px;
  display: none;
  color: #d93030;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message,
#success_page {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
}

.error_message {
  background-color: rgba(217, 48, 48, 0.1) !important;
  border: 2px solid rgba(217, 48, 48, 0.1) !important;
  color: #d93030 !important;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgba(28, 181, 159, 0.1) !important;
  border: 2px solid rgba(28, 181, 159, 0.1) !important;
  color: #1cb59f !important;
}

#success_page p {
  margin-bottom: 0 !important;
}

.filter-search-form .icons {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 1;
  color: var(--marketplaceColor);
  font-size: 20px;
}

.registration-form {
  position: relative;
}

.registration-form .submit-btn {
  border-radius: 8px;
}

.registration-form .form-control,
.registration-form .choices[data-type*='select-one'] .choices__inner {
  border-radius: 8px;
}

@media (min-width: 992px) {
  .filter-border:before {
    content: '';
    border-right: 1px solid #e9ecef;
    position: absolute;
    right: 0px;
    height: 40px;
    top: 10px;
    z-index: -1;
  }
  .registration-form {
    position: relative;
  }
  .registration-form .submit-btn {
    border-radius: 0px 6px 6px 0px;
  }
  .registration-form .form-control {
    border-radius: 6px 0px 0px 6px;
  }
  .registration-form .choices[data-type*='select-one'] .choices__inner {
    border-radius: 0;
  }
}

.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  box-shadow: none !important;
  border: 0;
  font-size: 15px;
  height: 60px;
  padding: 13px 6px 15px 45px;
  border-radius: 0;
}

.choices__inner {
  background: #f8f9fc !important;
}

.choices__list--dropdown,
.choices[data-type*='select-one'] .choices__input {
  background: #ffffff;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  z-index: 11111;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: #f8f9fc;
  color: #3c4858;
}

.choices__list--dropdown .choices__item--selectable {
  color: #8492a6;
}

.qs-datepicker-container {
  background-color: #ffffff;
  border-color: #dee2e6;
}

.qs-datepicker-container .qs-controls {
  background-color: #e9ecef;
}

.qs-datepicker-container .qs-controls .qs-month-year {
  font-weight: 600;
  font-size: 15px;
}

.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-bottom: 1px solid #e9ecef;
}

.qs-datepicker-container .qs-squares .qs-square {
  font-size: 15px;
  height: 34px;
  width: 34px;
}

.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
.qs-datepicker-container .qs-squares .qs-square.qs-current {
  background: var(--marketplaceColor);
  color: #ffffff;
  border-radius: 30px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
}

.qs-datepicker-container .qs-squares .qs-day {
  font-weight: 500;
  color: #8492a6;
}

/*********************************/
/*         Footer                */
/*===============================*/
footer {
  position: relative;
  color: #adb5bd;
  font-size: 15px;
}

footer .footer-py-120 {
  padding: 120px 0;
}

footer .footer-py-60 {
  padding: 60px 0;
}

footer .footer-py-30 {
  padding: 30px 0;
}

footer .footer-head {
  letter-spacing: 1px;
  font-weight: 500;
  color: #f8f9fc;
}

footer .text-foot {
  color: #adb5bd;
}

footer .footer-subscribe .form-control,
footer .foot-subscribe .form-control {
  background-color: #27314f;
  border: 1px solid #27314f;
  color: #f8f9fc !important;
}

footer .footer-subscribe input,
footer .foot-subscribe input {
  padding: 9px 20px;
  padding-right: 48px !important;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-webkit-input-placeholder,
footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-moz-placeholder,
footer .foot-subscribe input::-moz-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input:-ms-input-placeholder,
footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::-ms-input-placeholder,
footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

footer .footer-subscribe input::placeholder,
footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

footer .footer-subscribe button,
footer .foot-subscribe button {
  top: 4px;
  right: 5px;
}

footer .footer-subscribe button.btn,
footer .foot-subscribe button.btn {
  height: 44px;
  width: 44px;
}

footer .footer-subscribe.foot-white .form-control,
footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

footer .footer-list {
  margin-bottom: 0;
}

footer .footer-list li {
  margin-bottom: 10px;
}

footer .footer-list li a {
  transition: all 0.5s ease;
}

footer .footer-list li a:hover {
  color: #e6e8ea;
}

footer .footer-list li:last-child {
  margin-bottom: 0;
}

footer .footer-border,
footer .footer-bar {
  border-top: 1px solid #252f4c;
}

footer .footer-border-bottom {
  border-bottom: 1px solid #252f4c;
}

footer .border {
  border-color: #252f4c !important;
}

#style-switcher {
  transition: all 0.4s;
  background: none repeat scroll 0 0 #ffffff;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  left: -189px;
  position: fixed;
  top: 17%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;
}

#style-switcher div {
  padding: 5px 10px;
}

#style-switcher div h3 {
  color: #3c4858;
  font-size: 16px;
  margin: 0 3px 12px;
}

#style-switcher .bottom a.settings {
  background: none repeat scroll 0 0 #ffffff;
  height: 41px;
  position: absolute;
  right: -41px;
  top: 0px;
  width: 40px;
  padding: 3px;
  border-radius: 0 30px 30px 0;
}

ul.pattern {
  list-style: none outside none;
  overflow: hidden;
  padding: 0;
  border-radius: 0px;
}

ul.pattern li.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 0;
}

ul.pattern li a {
  cursor: pointer;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 3px;
}

ul.pattern .color1 {
  background-color: var(--marketplaceColor);
}

ul.pattern .color2 {
  background-color: #ffa432;
}

ul.pattern .color3 {
  background-color: #04724d;
}

ul.pattern .color4 {
  background-color: #ef2917;
}

ul.pattern .color5 {
  background-color: #7b2cbf;
}

ul.pattern .color6 {
  background-color: #eac435;
}

ul.pattern .color7 {
  background-color: #e40066;
}

ul .t-dark,
ul .t-rtl-light {
  display: inline-block;
}

ul .t-ltr-light,
ul .t-light,
ul .t-rtl-dark,
ul .t-ltr-dark {
  display: none;
}

@media only screen and (max-width: 375px) {
  #style-switcher {
    display: none;
  }
}
