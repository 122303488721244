@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.listingSectionContainer {
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 16px;
  margin-bottom: 16px;

  /* border: solid 3px #6e7491;
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;
  padding: 40px 60px;
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3); */
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}
.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features ul {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
  @media (--viewportMedium) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
