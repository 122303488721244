.root {
  display: inline-block;
}

.icon {
  color: red;
  display: inline-block;
  margin-left: 8px;
}

.container {
  max-width: 500px;
}
