@import '../../styles/customMediaQueries.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.bgHalf170 {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bgOverlay {
  background-color: rgba(60, 72, 88, 0.65);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bgGradientOverlay2 {
  background: linear-gradient(
    to bottom,
    rgba(22, 28, 45, 0.5) 0%,
    rgba(22, 28, 45, 0.6) 25%,
    rgba(22, 28, 45, 0.7) 50%,
    rgba(22, 28, 45, 0.8) 100%
  );
}

@media (max-width: 767px) {
  .bgHalf170 {
    padding: 50px 0;
    height: auto;
  }
}

.positionMiddleBottom {
  position: absolute;
  bottom: 15px;
  left: 12px;
  right: 12px;
  text-align: center;
}
